import { useEffect, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import useMultilang from "intl/useMultilang"
import Modal from "components/Modal/Modal"

function StornoPaymentModal(props) {
    
    const { amount, clearLocalModal, devicePaymentAgents, paymentAgentID, paymentUUID, handleStorno } = props
    
    const { getT } = useMultilang()

    const [selectedPaymentAgent, setSelectedPaymentAgent] = useState(null)

    useEffect(() => {
        for(let agent of devicePaymentAgents) {
            if(agent.paymentAgentID === paymentAgentID) setSelectedPaymentAgent(agent)
        }
    }, [devicePaymentAgents, paymentAgentID])

    if(selectedPaymentAgent == null) return null

    return(
        <Modal key={uuidv4()} className="storno-modal">
            <div className="box">
                <h2>{getT("payment.deletePayment.question")}</h2>
                <div className="amount">{amount} Ft</div>
                <div className="answers">
                    <div className="btn btn-cancel" onClick={() => handleStorno(selectedPaymentAgent, paymentUUID)}>
                        {getT("payment.deletePayment.answerYes")}
                    </div>            
                    <div className="btn btn-save" onClick={clearLocalModal}>
                        {getT("payment.deletePayment.answerNo")}
                    </div>
                </div>
            </div>
      </Modal>
    )
}
export default StornoPaymentModal