import { useContext } from "react"
import useMultilang from "intl/useMultilang"
import { WizardContext } from "./WizardContext"

function StepCircle(props) {

    const { stepName, number, onClick } = props
    const { activeStep, stepsDone } = useContext(WizardContext)

    const { getT } = useMultilang()

    return(
        <div className={`step ${(stepsDone != null && stepsDone.includes(stepName)) ? "done" : ""} ${activeStep === stepName ? "active" : ""}`} onClick={onClick}>
            <div className="inner-bg"></div>
            <div className="number">{number}</div>
            <div className="step-name">{getT(`settingWizard.steps.${stepName}`)}</div>
        </div>
    )
}
export default StepCircle