import { useRef, useContext } from "react";
import { ModalContext } from "contexts/ModalContext";
import Modal from "components/Modal/Modal";
import { v4 as uuidv4 } from "uuid";
import SelectionTable from "./selectionTable";

function InputFieldMultiSelect(props) {
  const { addModal, popModal } = useContext(ModalContext);
  const {
    className,
    title,
    errorText,
    onChange,
    headerFormat,
    options, // [{value,title}]
    selectedElements,
    onClose
  } = props;

  const ref = useRef();

  const openFilterModal = () => {
    let inLineStyle = ref.current.getBoundingClientRect();
    addModal(
      <Modal
        key={uuidv4()}
        className={`InputFieldMultiSelect-modal ${(className) ? className+"-InputFieldMultiSelect-modal": ""}`}
        style={{ modalContent: { top: inLineStyle.y, left: inLineStyle.x } }}
        onClickLayout={handleClose}
        parentRef={ref}
      >
        <div className="filter-container">
          <SelectionTable
            options={options}
            selectedElements={selectedElements}
            onChange={onChange}
          />
        </div>
      </Modal>
    );
  };

  const handleClose = () => {
    if(typeof onClose === "function") onClose()
    popModal()
  }

  const returnErrorText = () => {
    if (errorText.constructor !== Array) {
      return (
        <div className="error-row" style={{ bottom: -22 }}>
          {errorText}
        </div>
      );
    }
    let temp = errorText.map((row, index) => {
      return (
        <div className="error-row" style={{ bottom: -index * 18 - 22 }}>
          {row}
        </div>
      );
    });
    return temp;
  };

  return (
    <div
      className={`input-field-multi-select-main-container ${
        errorText ? "show-error" : ""
      } textField-root ${className?className: ""}`}
    >
      <label className="textField-label shrink">{title}</label>
      {errorText && returnErrorText()}
      <div ref={ref} id={uuidv4()} className="textField-base">
        <div className="textField-input" onClick={openFilterModal}>
          {headerFormat(selectedElements)}
        </div>
      </div>
    </div>
  );
}
export default InputFieldMultiSelect;
