import { useContext, useState } from "react"
import { useVip } from "okeoke.client"
import Item from "./Item"
import useMultilang from "intl/useMultilang"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"
import OrderActions from "views/Sell/OrderSummary/OrderActions"
import { PaymentContext } from "contexts/PaymentContext"
import NumberFormat from "react-number-format"

function PaymentOrderSummary() {

  const { devicePaymentAgents, proceedPayment, paymentsNeeded } = useContext(PaymentContext)
  const { takeAway, orderItemsAssembled, orderIdentifier, notes, orderVipID } = useContext(SelectedOrderContext)
  const { firstName, money } = useVip(orderVipID)
  const { getT } = useMultilang()

  const [showDetails, setShowDetails] = useState(localStorage['showItemDetailsInBasket'])

  const items = Object.values(orderItemsAssembled || {}).filter(assembledItem => assembledItem?.count > 0)

  const toggleShowItemDetails = () => {
    setShowDetails(showDetails ? false : "1")
    showDetails ? localStorage.removeItem("showItemDetailsInBasket") : localStorage["showItemDetailsInBasket"] = "1"
  }

  const startQuickPay = agent => {

    proceedPayment(paymentsNeeded, agent)
  }

  return (
    <>
      <div className="order-summary-header">
        <div className="order-summary-title">
          {orderVipID != null && firstName != null && firstName !== "" && `${firstName} ${money > 0 ? "("+money+" Ft)" : ""}`}
          {(orderVipID == null || firstName == null || firstName === "") && orderIdentifier === "" ? getT("sell.basket.order") : orderIdentifier}
        </div>
      </div>
      <OrderActions toggleShowItemDetails={toggleShowItemDetails} showDetails={showDetails} />
      <div className={`order-summary-container closed`}>
        <div className="order-summary-body">
          <div className="order-summary-items-container">
            <div className="order-summary-items-body">
              {items.length < 1 && <div className="empty-basket">{getT("sell.basketEmpty")}</div>}            
              {items.map(assembledItem => 
                <Item
                  key={assembledItem.orderItemUUID}
                  assembledItem={assembledItem}
                  orderTakeAway={takeAway}
                  showDetails={localStorage['showItemDetailsInBasket']}
                  readOnly={true}
                />
              )}
              {notes != null && notes !== "" && <div className="basket-item-container notes">{`${getT("item.notes")}: ${notes}`}</div>}
            </div>
          </div>
        </div>
      </div>
      <div className="quick-pay-btn-container">
      {devicePaymentAgents
        .filter(paymentAgent => paymentAgent.agentType === "cash" || paymentAgent.agentType === "bankCard")        
        .map((paymentAgent, index) => 
          <div key={index} className="btn btn-highlighted" onClick={() => startQuickPay(paymentAgent)}>
            {paymentAgent.agentType === "cash" && getT("sell.quickCashPay")}
            {paymentAgent.agentType === "bankCard" && getT("sell.quickCreditCardPay")}
            <NumberFormat
              value={paymentsNeeded}
              decimalSeparator=","
              suffix=" Ft"
              prefix=": "
              thousandSeparator="."
              displayType="text"
            />
          </div>)
      }
      </div>
    </>
  )
}
export default PaymentOrderSummary