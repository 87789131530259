import { useState } from "react"
import useMultilang from "intl/useMultilang"
import TextField from "components/TextField"

function NotesModal(props) {

    const { notes, setNotes, popModal } = props

    const { getT } = useMultilang()

    const [value, setValue] = useState(notes)

    const handleChange = e => setValue(e.target.value) 

    const handleSave = () => {
        setNotes(value)
        popModal()
    }

    return(
        <>
            <h2>{getT("sell.basket.notes")}</h2>
            <TextField 
                value={value}
                onChange={handleChange}
                type="textarea"
                className="order-note-modal-field"
            />
            <div className="button-container" style={{display: "flex", justifyContent: "center", gap: "12px", marginTop: "12px"}}>
                <div className="btn btn-cancel" onClick={popModal}>
                    {getT("identifier.modal.cancel")}
                </div>
                <div className={`btn btn-save`} onClick={handleSave}>
                    {getT("identifier.modal.next")}
                </div>
            </div>
        </>
    )
}
export default NotesModal