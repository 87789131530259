import { useIPanelApi, useClient } from "okeoke.client"

export default function useDevicesApi() {

    const { post } = useIPanelApi()
    const { brandID, selectedLocationUUID } = useClient()
    
    const setDevice = async (device) => {
      try {
        const body = {
          brandID,
          device: { ...device, locationUUID: selectedLocationUUID }
        }
        const response = await post("brand/devices/basic", body)
        return response
      } catch (error) {
        console.log(error)
        return { success: false }
      }
    }

    return {
      setDevice
    }
} 