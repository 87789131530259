import useMultilang from "intl/useMultilang"

function PluggedModal(props) {

    const { plugged, popModal } = props
    
    const { getT } = useMultilang()
    
    return(
        <div className="plugged-modal">
            <div className="text">{getT(`connection.${plugged ? "online" : "offline"}`)}</div>
            <div className="btn btn-highlighted" onClick={popModal}>{getT(`connection.done`)}</div>
        </div>
    )
}
export default PluggedModal