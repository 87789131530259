import React, { useState, useEffect } from "react";
import LoadSvg from "components/LoadSvg-v2";

function InputSwitch(props) {
  const {
    label,
    errorText,
    className,
    name,
    checked, // boolean, int, string
    onChange,
    isCheckbox,
    svgNameOn,
    svgNameOff,
  } = props;

  const [check, setCheck] = useState();

  useEffect(() => {
    if(checked != null){
    setCheck(acceptChecked());
    }
    //eslint-disable-next-line
  }, [checked]);

  const handleChange = () => {
    if (typeof onChange === "function") {
      switch (typeof checked) {
        case "string":
          returnString();
          break;
        case "number":
          returnInt();
          break;
        case "boolean":
          returnBoolean();
          break;
        default:
          break;
      }
    }
  };

  const returnString = () => {
    onChange({ target: { checked: check ? "false" : "true", name } });
  };

  const returnInt = () => {
    onChange({ target: { checked: check ? 0 : 1, name } });
  };

  const returnBoolean = () => {
    onChange({ target: { checked: check ? false : true, name } });
  };

  const acceptChecked = () => {
    switch (typeof checked) {
      case "string": return checked.toLowerCase() === "true" ? true : false;
      case "number": return checked === 1 ? true : false;
      case "boolean": return checked;
      default: return null
    }
  };

  const returnErrorText = () => {
    if (!errorText) return null;
    if (errorText.constructor !== Array) {
      return (
        <div className="error-row" style={{ bottom: -22 }}>
          {errorText}
        </div>
      );
    }
    let temp = errorText.map((row, index) => {
      return (
        <div
          key={index}
          className="error-row"
          style={{ bottom: -index * 18 - 22 }}
        >
          {row}
        </div>
      );
    });
    return temp;
  };

  return (
    <>
      {isCheckbox && (
        <div className={`okeoke-input-switch ${className ? className : ""}`}>
          <div className="checkbox-container" onClick={handleChange}>
            <div className="label">{label}</div>
            {check && (
              <div className="checkbox checkbox-on">
                {<LoadSvg name={svgNameOn ? svgNameOn : "check"} />}
              </div>
            )}
            {!check && (
              <div className="checkbox checkbox-off">
                {<LoadSvg name={svgNameOff ? svgNameOff : null} />}
              </div>
            )}
          </div>
          <div className="error">{returnErrorText()}</div>
        </div>
      )}
      {!isCheckbox && (
        <div className={`okeoke-input-switch ${className ? className : ""}`}>
          <div className="switch-container" onClick={handleChange}>
            <div className="label">{label}</div>
            <div className={`slider ${check ? "switch-on" : "switch-off"}`}>
              <div className={`thumb ${check ? "thumb-on" : "thumb-off"}`}></div>
            </div>
          </div>
          <div className="error">{returnErrorText()}</div>
        </div>
      )}
    </>
  );
}

export default InputSwitch;
