import { useOrderItem, OrderStatus, OrderItemStates } from 'okeoke.client'
import useMultilang from "intl/useMultilang"

function OrderItemController(props) {

  const { orderUUID, orderItemUUID, onClose } = props
  const { getT } = useMultilang()

  const { addStatus, assembledItem } = useOrderItem(null, orderUUID, orderItemUUID)

  const setOrderItemReady = (cid) => {
    addStatus(OrderStatus.ORDER_ITEM_DONE, { cid })
    onClose()
  }

  const list = Object.values(assembledItem?.prep || {})

  return (
    <>
      <div className="card-order-controller-background animation-appear-seventy" onClick={onClose}></div>
      <div className="card-order-controller animation-appear-full">
        {list.length < 1 && getT("orderController.noPrepCounters")}
        {list
          .filter(prep => prep.state === OrderItemStates.PREPARING )
          .map((prep, index) => <div key={index} className="btn btn-highlighted" onClick={() => setOrderItemReady(prep.id)}>{prep.name} {getT("orderController.ready")}</div>)
        }        
        <div className="btn" onClick={onClose}>{getT("orderController.close")}</div>
      </div>
    </>
  )
}
export default OrderItemController