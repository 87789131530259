import useMultilang from "intl/useMultilang"

function AddBankCardPaymentModal(props) {

    const { popModal, amount, addCardPayment, paymentAgentID } = props
    const { getT } = useMultilang()
    
    const onClickPaymentOk = ()  => {
      addCardPayment(amount, paymentAgentID)
      popModal()
    }

    return(
        <div className="open-in-modal-root">
            <div className="text">{getT("modal.bankCardPayment.error.title")}</div>
            <div className="text2">{getT("modal.bankCardPayment.error.message")}</div>
            <div className="button-cotainer">
              <div className="btn" onClick={onClickPaymentOk}>
                {getT("modal.bankCardPayment.error.paymentOK")}
              </div>
              <div className="btn" onClick={popModal}>
                {getT("modal.bankCardPayment.error.ok")}
              </div>
            </div>
        </div>
    )
}
export default AddBankCardPaymentModal