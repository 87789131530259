import { useState, createContext, useEffect } from "react"
import {  isMobileOnly } from "react-device-detect"
import useMultilang from "intl/useMultilang"

const TutorialContext = createContext()

function TutorialContextProvider(props) {

  const { getT } = useMultilang()

  const [tutorialOn, setTutorialOn] = useState(false)
  const [sectionsVisible, setSectionsVisible] = useState({ leftSide: false, header: false, center: false, summary: false })
  const [sectionContent, setSectionContent] = useState({ leftSide: null, header: null, center: null, summary: null }) 

  useEffect(() => {
    if(localStorage['firstStartTutorialOn']) {
      setTutorialOn(true)
      setAllSectionVisibility(true)
    }
    //eslint-disable-next-line
  }, [])

  const setSectionVisibility = (section, visible) => {
    setSectionsVisible({...sectionsVisible, [section]: visible})
  }

  const setAllSectionVisibility = (visible) => {
    let obj = {}
    for(let section of Object.keys(sectionsVisible)) {
      obj[section] = visible
    }
    setSectionsVisible(obj)
  }

  const setContent = (section, content) => {
    setSectionContent({...sectionContent, [section]: content})
  }

  const setContents = (contentArray) => {
    console.log("Contents set")
    let obj = {...sectionContent}
    for(let element of contentArray) {
      obj[element.section] = element.content
    }
    setSectionContent(obj)
  }

  const setSectionsVisibility = (visibilityArray) => {
    console.log("Visibilities set")
    let obj = {...sectionsVisible}
    for(let element of visibilityArray) {
      obj[element.section] = element.visibility
    }
    setSectionsVisible(obj)
  }

  const removeAllContent = () => {
    console.log("All tutorial content removed")
    let obj = {}
    for(let section of Object.keys(sectionContent)) {
      obj[section] = null
    }
    setSectionContent(obj)
  }

  const endTutorial = () => {
    setAllSectionVisibility(false)
    removeAllContent()
    setTutorialOn(false)
  }

  return (
    <TutorialContext.Provider
      value={{
        setSectionVisibility,
        setAllSectionVisibility,
        setSectionsVisibility,
        setContent,
        setContents,
        removeAllContent,
        endTutorial,
        startTutorial: () => setTutorialOn(true),
        tutorialOn
      }}
    >
      {!isMobileOnly && tutorialOn && <div className="tutorial-layer">
        <div className={`left-side-root open ${sectionsVisible.leftSide ? "visible" : "not-visible"}`}>{sectionContent.leftSide}</div>
        <div className={`header-root ${sectionsVisible.header ? "visible" : "not-visible"}`}>{sectionContent.header}</div>
        <div className={`center-root ${sectionsVisible.center ? "visible" : "not-visible"}`}>{sectionContent.center}</div>
        <div className={`summary-root ${sectionsVisible.summary ? "visible" : "not-visible"}`}>{sectionContent.summary}</div>
        <div className="tutorial-exit-btn" onClick={endTutorial}>{getT("tutorial.exit")}</div>
      </div>}
      {props.children}
    </TutorialContext.Provider>
  )
}

export { TutorialContextProvider, TutorialContext }
