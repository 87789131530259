import LoadSvg from "components/LoadSvg-v2"
import { useOrder } from "okeoke.client"
import { useNavigate } from "react-router-dom"

function BasketRow(props) {
  
  const { selectedOrderUUID, setSelectedOrderUUID, orderUUID, popModal } = props

  const { abort, discountPrice, orderIdentifier } = useOrder(null, orderUUID)

  const navigate = useNavigate()
  
  const handleBasketDelete = (e) => {
    if(selectedOrderUUID === orderUUID) return null
    e.stopPropagation()
    abort()
  }

  const handleSelectBasket = () => {
    popModal()
    setSelectedOrderUUID(orderUUID)
    navigate("/")
  }

  return (
    <div
      className={`basket-element ${selectedOrderUUID === orderUUID ? "selected" : ""}`}
      onClick={handleSelectBasket}
    >
      {selectedOrderUUID !== orderUUID && <LoadSvg name={"BinIcon"} onClick={(e)=>{handleBasketDelete(e)}} className="icon-btn"/>}
      {selectedOrderUUID === orderUUID && <LoadSvg name={"check"} className="icon-btn"/>}
      <div className="basket-name">{orderIdentifier || "Basket"}</div>
      <div className="basket-price">{discountPrice}</div>
    </div>
  )
}

export default BasketRow
