import { useContext } from "react"
import Clock from "react-live-clock"
import { useDevice } from "okeoke.client"
import Modal from "components/Modal/Modal"
import { v4 as uuidv4 } from "uuid"
import DeviceDialog from "views/LeftBar/DeviceDialog"
import { ModalContext } from "contexts/ModalContext"

function InfoBox() {
    
    const { loggedInUser } = useDevice()
    const { addModal, popModal } = useContext(ModalContext)
    
    const openDeviceDialog = () => {
        addModal(
          <Modal key={uuidv4()} className="pos-modal-base-skin" onClickLayout={popModal}>
            <DeviceDialog/>
          </Modal>
        )
    }

    return(
        <div className="header-left-side-floater">
            <img onClick={openDeviceDialog} src="https://cdn.okeoke.io/global/barsoft/Barsoft%20logo%20only.svg" alt="logo_small" />
            <div className="location-name-user-container" onClick={openDeviceDialog}>
            {/* <div className="location-name">{location.name}</div> */}
            <div className="user-name">{loggedInUser?.firstName}</div>
            <div className="time-container">
                <div className="topBar-Date">
                <Clock
                    format={"YYYY-MM-DD"}
                    ticking={true}
                    timezone={"Europe/Budapest"}
                    interval={86400000}
                />
                </div>
                <div className="topBar-Time">
                <Clock
                    format={"HH:mm:ss"}
                    ticking={true}
                    timezone={"Europe/Budapest"}
                />
                </div>
            </div>
            </div>
        </div>
    )
}
export default InfoBox