import { useState, useEffect } from "react"
import LoadSvg from "components/LoadSvg-v2"
import NumberFormat from 'react-number-format'
import useMultilang from "intl/useMultilang"

function TipNumpad(props) {
  
  const { onChange, value, paymentsWithoutTip } = props

  const [current, setCurrent] = useState(""+value)
  const { getT } = useMultilang()

  useEffect(() => {
    setCurrent(""+value)
  }, [value])

  useEffect(() => {
    if(typeof onChange === "function") onChange(current)
    // eslint-disable-next-line
  }, [current])

  const changeAmount = (e) => {
    if (e === "." && (""+current).includes(".")) return null
    if (""+current === "0" && e === "0") return null
    if (""+current === "0" && e === "00") return null
    if (""+current === "0" && e === ".") {
      setCurrent("0.")
    } else {
      if (""+current === "0") {
        setCurrent("" + e)
      } else {
        if(current < 100000) setCurrent(current + "" + e)
      }
    }
  }

  return (
    <div className="payment-numpad-root">
      <div className="payments-without-tip">{`${getT("tipModal.payments")}:`} {<NumberFormat value={paymentsWithoutTip} decimalSeparator="," suffix=" Ft" thousandSeparator="." displayType="text"/>}</div>
      <div className="current-number">
        <div className="text">{`${getT("tipModal.tipAmount")}:`}</div>
        {<NumberFormat value={current} decimalSeparator="," suffix=" Ft" thousandSeparator="." displayType="text"/>}
        <div className="clear" onClick={() => setCurrent("0")}><LoadSvg name="BinIcon" /></div>
        </div>
      <div className="payment-numpad-body">
        <div className="numpad-btn" onClick={() => changeAmount("7")}>
          <div className="number">7</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("8")}>
          <div className="number">8</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("9")}>
          <div className="number">9</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount(".")}>
          <div className="number">,</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("4")}>
          <div className="number">4</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("5")}>
          <div className="number">5</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("6")}>
          <div className="number">6</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("0")}>
          <div className="number">0</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("1")}>
          <div className="number">1</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("2")}>
          <div className="number">2</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("3")}>
          <div className="number">3</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("00")}>
          <div className="number">00</div>
        </div>
      </div>
    </div>
  )
}
export default TipNumpad