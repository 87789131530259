import { useContext, useState, useRef, useEffect } from "react"
import Actions from "./Actions"
import { WizardContext } from "./WizardContext"
import NtakEditProvider from "components/NtakEditProvider"
import useMultilang from "intl/useMultilang"
import { toast } from "react-toastify"
import useNtakApi from "apis/useNtakApi"
import useLocationsConfigApi from "apis/locations/useLocationsConfigApi"
import InputFieldSelect from "components/InputFieldSelect"

function Ntak() {

    const { activeStep, goToPrev, goToNext } = useContext(WizardContext)
    const { getT } = useMultilang()
    const { getLocationConfig, setLocationConfig } = useLocationsConfigApi()
    const { setProviders, getProviders } = useNtakApi()
    const [ntakData, setNtakData] = useState({ regNumber: "", taxNumber: "", key: "", certificate: "" })
    const [ntakProviderList, setNtakProviderList] = useState(null)
    const [locationConfigData, setLocationConfigData] = useState(null)
    const [selectedOption, setSelectedOption] = useState("addNew")
    const validated = useRef(null)

    useEffect(() => {
        loadLocationConfig()
        loadProviders()
        // eslint-disable-next-line
    }, [])

    const loadLocationConfig = () => {
        getLocationConfig().then(res => {
            if(res.success) {
                setLocationConfigData(res.data)
            } else {
                console.log(res)
            }
        })
    }

    const loadProviders = () => {
        getProviders().then(res => {
            if(res.success) {
                let providersArray = res.data.map(provider => { return {title: provider.regNumber, value: provider.id} })
                setNtakProviderList(providersArray)
            } else {
                console.log(res)
            }
        })
    }

    const handleClickPrev = () => {
        goToPrev()
    }

    const handleClickNext = () => {
        if(selectedOption === "skip") {
            goToNext()
        } else {
            if(locationConfigData.ntakProviderID == null || locationConfigData.ntakProviderID === 0) {
                handleSave()
            } else {
                if(selectedOption === "addNew") {
                    let valid = validate()
                    if(valid) handleSave()
                } else {
                    goToNext()
                }
            }
        }
    }

    const handleDataUpdate = (data) => {
        setNtakData({...ntakData, ...data})
    }

    const handleSave = () => {
        let valid = validate()
        if(valid) {
            let params = {
                taxNumber: ntakData.taxNumber,
                regNumber: ntakData.regNumber,
                key: ntakData.key,
                certificate: ntakData.certificate
            }
            setProviders(params).then(res => {
                if(res.success) {
                    saveLocationConfig(res.data.id)
                } else {
                    toast.error("error")
                    console.log(res)
                }
            })
        }
    }

    const saveLocationConfig = (ntakProviderID) => {
        let newValues = {...locationConfigData, ntakProviderID}
        setLocationConfig(newValues).then(res => {
            if(res.success) {
                loadProviders()
                loadLocationConfig()
                goToNext()
            } else {
                toast.error(getT("error"))
            }
        })
    }

    const validate = () => {
        validated.current = true
        let valid = (ntakData.regNumber !== "" && ntakData.taxNumber !== "")
        if(valid) valid = ntakData.regNumber.length === 10
        if(valid) valid = ntakData.taxNumber.replace(/_/g, "").length === 11
        if(valid) valid = ntakData.key !== "" && ntakData.certificate !== ""
        if(!valid) toast.error(getT('settingWizard.ntak.missingData'))
        return valid
    }

    const handleProviderChange = (e) => {
        saveLocationConfig(e.target.value)
    }

    if(activeStep !== "ntak") return null

    return(
        <>
        <div className="wizard-step">
            <div className="title">{getT("settingWizard.ntak.title")}</div>
            <div className="ntak-outer-container">
                <div className="ntak-settings-container">
                    <div className="ntak-options-container">
                        <div className={`btn ${selectedOption === "addNew" ? "btn-highlighted" : ""}`} onClick={() => setSelectedOption("addNew")}>{getT('settingWizard.ntak.addNewProvider')}</div>
                        <div className={`btn ${selectedOption === "skip" ? "btn-highlighted" : ""}`} onClick={() => setSelectedOption("skip")}>{getT('settingWizard.ntak.skip')}</div>
                        {(ntakProviderList || []).length > 0 && <div className={`btn ${selectedOption === "select" ? "btn-highlighted" : ""}`} onClick={() => setSelectedOption("select")}>{getT('settingWizard.ntak.selectFromProviders')}</div>}
                    </div>
                    {selectedOption === "select" && (ntakProviderList || []).length > 0 && 
                        <InputFieldSelect
                            label={getT('settingWizard.ntakProviderID')}
                            displayedDefaultName={getT('settingWizard.selectNtakProviderID')}
                            onChange={handleProviderChange}
                            name="ntakProviderID"
                            options={ntakProviderList}
                            value={locationConfigData.ntakProviderID}
                        />
                    }   
                    {selectedOption === "addNew" && <NtakEditProvider onDataUpdate={handleDataUpdate} data={ntakData} validated={validated.current} />}
                </div>
                <div className="help-text-container">
                    {getT('settingWizard.ntak.helpText')}
                </div>
            </div>
        </div>
        <Actions onClickPrev={handleClickPrev} onClickNext={handleClickNext} />
        </>
    )
}
export default Ntak