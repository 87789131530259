import useMultilang from "intl/useMultilang"
import { useNavigate } from "react-router-dom"

function PrinterErrorModal(props) {

    const { popModal, closeAllModal } = props

    const { getT } = useMultilang()
    const navigate = useNavigate()

    const handleGoToPrinterSettings = () => {
        navigate("admin/fiscal-printer")
        closeAllModal()
    }

    return(
        <div className="printer-error-modal">
            <h2>{getT("payment.fiscalPrinterError.modalTitle")}</h2>
            <p>{getT("payment.fiscalPrinterError.modalText")}</p>
            <div className="buttons-container">
                <div className="btn" onClick={popModal}>{getT("payment.fiscalPrinterError.later")}</div>
                <div className="btn btn-highlighted" onClick={handleGoToPrinterSettings}>{getT("payment.fiscalPrinterError.goToPrinterSettings")}</div>
            </div>
        </div>
    )
}
export default PrinterErrorModal