import { useIPanelApi, useClient } from "okeoke.client"

export default function useLocationsConfigApi() {

  const { get, post } = useIPanelApi()
  const { brandID, deviceLocationUUID } = useClient()

  const getLocationsConfig = async () => {
    try {
      const response = await get(`brand/locations/config/all?brandID=${brandID}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const getLocationConfig = async () => {
    try {
      const response = await get(`brand/locations/config/${deviceLocationUUID}?brandID=${brandID}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const setLocationConfig = async (locationConfig) => {
    try {
      const body = {
        brandID,
        config: {...locationConfig, uuid: deviceLocationUUID}
      }
      const response = await post(`brand/locations/config`, body)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  return {
    getLocationsConfig,
    getLocationConfig,
    setLocationConfig
  }
}