import { useContext } from "react"
import { useBaskets } from "okeoke.client"
import BasketRow from "./BasketRow"
import { ModalContext } from "contexts/ModalContext"
import LoadSvg from "components/LoadSvg-v2"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"
import useMultilang from "intl/useMultilang"
import { useNavigate } from "react-router-dom"

function BasketsModal() {

  const { baskets } = useBaskets()
  const { popModal } = useContext(ModalContext) 
  const { startOrder, selectedOrderUUID, handleSelectOrderUUID } = useContext(SelectedOrderContext) 
  const { getT } = useMultilang()
  const navigate = useNavigate()

  const handleStartNewBasket = () => {
    startOrder()
    navigate("/")
    popModal()
  }

  return (
    <div className="basket-modal-root">
      <div className="basket-modal-header">
        <div className="back-icon" onClick={popModal}>
            <LoadSvg name={"ArrowLeftIcon"} />
          </div>
        <div className="header-text" onClick={popModal}>{getT("orderItem.view.back")}</div>
        <div className="title">{getT("baskets.modalTitle")}</div>
      </div>
      <div className="baskets">
        {Object.values(baskets || {})
        .map(basket => 
          <BasketRow
          key={basket.order?.uuid}
          orderUUID={basket.order?.uuid}
          setSelectedOrderUUID={handleSelectOrderUUID}
          selectedOrderUUID={selectedOrderUUID}
          popModal={popModal}
          />
          )}
      </div>
      <div className="btn btn-save" onClick={handleStartNewBasket}>{getT("baskets.startNewBasket")}</div>
    </div>
  )
}
export default BasketsModal