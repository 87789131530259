import React, { useState, useEffect } from "react";
import LoadSvg from "components/LoadSvg-v2";

function SelectionTable(props) {
  const { options, selectedElements, onChange } = props;
  const [localSelectedElements, setLocalSeletedElements] =
    useState(selectedElements);
  const [isChanged, setIsChanged] = useState(false);

  const deployDataListElements = () => {
    if (!options) return null;
    let temp = options.map((element, index) => {
      return (
        <div
        key={index}
          className={`list-element ${isSelected(element) ? "selected" : ""}`}
          onClick={() => {
            LocalhandleClickSelect(element);
          }}
        >
          {isSelected(element) && (
            <div className="svg-container">
              <LoadSvg name="check" />
            </div>
          )}
          <label className="label">{element.title}</label>
        </div>
      );
    });
    return temp;
  };

  const LocalhandleClickSelect = (e) => {
    let temp = localSelectedElements;
    if (isSelected(e)) {
      temp = temp.filter(
        (element) => JSON.stringify(element) !== JSON.stringify(e.value)
      );
    } else {
      temp = [...temp, e.value];
    }
    setIsChanged(true);
    setLocalSeletedElements(temp);
  };

  const isSelected = (e) => {
    let temp = false;
    for(let element of localSelectedElements) {
      if (JSON.stringify(element) === JSON.stringify(e.value)) temp = true;
    }
    return temp;
  };

  useEffect(() => {
    if (isChanged) {
      onChange(localSelectedElements);
      setIsChanged(false);
    }
    // eslint-disable-next-line
  }, [localSelectedElements]);

  return (
    <div className="okeoke-input-field-multi-select-selection-table">
      {deployDataListElements()}
    </div>
  );
}

export default SelectionTable;
