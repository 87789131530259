import Ntak from "./Ntak"
import TippType from "./TippType"
import Welcome from "./Welcome"
import Done from "./Done"
import FiscalPrinter from "./FiscalPrinter"
import PosPrinter from "./PosPrinter"
import ServiceFee from "./ServiceFee"
import { WizardContextProvider } from "./WizardContext"

function SettingsWizard(props) {

    const { location } = props
    
    return(
        <WizardContextProvider location={location} >
            <Welcome />
            <Ntak />
            <TippType />
            <Done />
            <FiscalPrinter />
            <PosPrinter />
            <ServiceFee />
        </WizardContextProvider>
    )
}
export default SettingsWizard