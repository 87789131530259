import useMultilang from "intl/useMultilang"

function LoginMessage() {

    const { getT } = useMultilang()

    return(
        <div className="left-side">
            <div className="title">{getT("tutorial.login.welcomeTitle")}</div>
            <div className="message">
                {getT("tutorial.login.welcomeMessage")}
            </div>
        </div>
    )
}
export default LoginMessage