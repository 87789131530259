import useMultilang from "intl/useMultilang"
import Actions from "./Actions"
import { WizardContext } from "./WizardContext"
import { useContext } from "react"

function Done() {

    const { activeStep, goToPrev, goToNext } = useContext(WizardContext)

    const handleClickPrev = () => {
        goToPrev()
    }

    const handleClickNext = () => {
        goToNext()
    }
    
    const { getT } = useMultilang()

    if(activeStep !== "done") return null

    return(
        <>
        <div className="wizard-step">
            <div className="title">{getT("settingWizard.doneTitle")}</div>
            <div className="text">{getT("settingWizard.doneText")}</div>
        </div>
        <Actions onClickPrev={handleClickPrev} onClickNext={handleClickNext} />
        </>
    )
}
export default Done