import { createContext, useState, useEffect, useRef } from "react"
import { useClient, useDevice } from "okeoke.client"
import moment from "moment"
import useOrdersApi from "apis/useOrdersApi"

const PreviousOrderContext = createContext()

const PreviousOrderProvider = (props) => {

  const { getOrders } = useOrdersApi()
  const { brandID, deviceLocationUUID, socketAuthenticated, sandboxMode } = useClient()
  const { loggedInUser, deviceUUID } = useDevice()

  const [selectedOrderUUID, setSelectedOrderUUID] = useState(null)
  const [orders, setOrders] = useState({})
  const [filters, setFilters] = useState({})
  const [loading, setLoading] = useState(true)
  const timer = useRef(null)

  useEffect(() => {

    clearInterval(timer.current)
    if(loggedInUser != null && socketAuthenticated) loadOrders()
    // timer.current = setInterval(() => loadOrders(), 30000)

    // return () => clearInterval(timer.current)

    // eslint-disable-next-line
  }, [brandID, deviceLocationUUID, socketAuthenticated, loggedInUser, filters])


  const handleSetFilters = (values) => {
    setFilters({...filters, ...values})
  }

  const refreshOrders = () => {
    loadOrders()
  }

  const loadOrders = () => {
    
    setLoading(true)

    let from = filters.from || `${moment().format("YYYY-MM-DD")} 00:00:00`
    let to = filters.to || `${moment().format("YYYY-MM-DD")} 23:59:59`
    let insertDeviceUUID = filters.insertDeviceUUID || [deviceUUID]
    let deviceTypes = filters.deviceTypes || []

    let filter = {
      locations: [deviceLocationUUID],
      ordersDateRange: {
        fromDate: from,
        toDate: to
      },
      sandbox: sandboxMode ? 1 : 0,
      insertDeviceUUID,
      deviceTypes
    }

    console.log("loadOrders filter: ", filter)

    let filterString = JSON.stringify(filter)

    getOrders(filterString).then(res => {
      if(res.success) {
        let obj = {}
        for(let order of res.data) {
          obj[order.uuid] = order
        }
        setOrders(obj)
        //console.log(res)
      } else {
        console.log("Get orders error", res)
      }
    }).finally(() => setTimeout(() => setLoading(false), 300))
  }

  return (
    <PreviousOrderContext.Provider
      value={{
        orders,
        selectedOrderUUID,
        setSelectedOrderUUID,
        refreshOrders,
        filters,
        setFilters: handleSetFilters,
        loading
      }}
    >
      {props.children}
    </PreviousOrderContext.Provider>
  )
}

export { PreviousOrderContext, PreviousOrderProvider }
