import { useIPanelApi, useClient } from "okeoke.client"

export default function usePrintersApi() {

    const { get, post } = useIPanelApi()
    const { brandID, selectedLocationUUID } = useClient()
    
    const getPrinters = async (filter) => {
      try {
        const response = await get(`brand/printers?brandID=${brandID}&filter=${filter}`)
        return response
      } catch (error) {
        console.log(error)
        return { success: false }
      }
    }

    const getPrinter = async (printerUUID) => {
      try {
        const response = await get(`brand/printers/${printerUUID}?brandID=${brandID}`)
        return response
      } catch (error) {
        console.log(error)
        return { success: false }
      }
    }

    const setPrinter = async (printer) => {
      let body = {
          brandID,
          printer: { ...printer, locationUUID: selectedLocationUUID }
      }
      try {
        const response = post(`brand/printers/`, body)
        return response
      } catch (error) {
        console.log(error)
        return { success: false }
      }
    }

    return {
      getPrinters,
      getPrinter,
      setPrinter
    }
} 