import useMultilang from "intl/useMultilang"
import { useContext } from "react"
import { WizardContext } from "./WizardContext"
import { ModalContext } from "contexts/ModalContext"

function Actions(props) {

    const { onClickPrev, onClickNext } = props

    const { activeStep, steps, handleClickFinish, finished } = useContext(WizardContext)
    const { popModal } = useContext(ModalContext)
    const { getT } = useMultilang()

    if(activeStep == null) return null

    return(
        <div className="actions">
            <div className={`btn btn-cancel`} onClick={popModal}>{getT("settingWizard.actions.cancel")}</div>
            <div className={`btn btn-prev ${activeStep === steps[0] ? "disabled" : ""}`} onClick={onClickPrev}>{getT("settingWizard.actions.prev")}</div>
            <div className={`btn btn-highlighted btn-next ${activeStep === steps[(steps || []).length-1] ? "disabled" : ""}`} onClick={onClickNext}>{getT("settingWizard.actions.next")}</div>
            <div className={`btn btn-save btn-finish ${finished ? "" : "disabled"}`} onClick={handleClickFinish}>{getT("settingWizard.actions.finish")}</div>
        </div>
    )
}
export default Actions