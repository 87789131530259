import { useState, createContext, useEffect } from "react"
import { useClient } from "okeoke.client"
import merge from 'lodash.merge'

const PersonalSettingsContext = createContext()

const PersonalSettingsProvider = (props) => {

  const { appParams } = useClient()
  const [localAppParams, setLocalAppParams] = useState({})
  const [customization, setCustomization] = useState({})

  useEffect(() => {
    initAppParams()
    initCustomization()
    // eslint-disable-next-line
  }, [appParams])

  const initAppParams = () => {
    let localSavedParams = {}
    try {
      let savedParams = localStorage["pos-saved-params"]
      if(savedParams != null) localSavedParams = JSON.parse(savedParams)
    } catch(e) {
      console.log(e)
    }
    let newValues = merge(appParams, localSavedParams)
    setLocalAppParams(newValues)
  }

  const initCustomization = () => {
    let localSavedParams = {}
    try {
      let savedParams = localStorage["pos-saved-customization"]
      if(savedParams != null) localSavedParams = JSON.parse(savedParams)
    } catch(e) {
      console.log(e)
    }
    setCustomization(localSavedParams)
  }

  const changeLocalAppParam = (group, name, value) => {
    let newValues = { ...localAppParams, [group]: {...localAppParams?.[group], [name]: value }}
    localStorage["pos-saved-params"] = JSON.stringify(newValues)
    setLocalAppParams(newValues)
  }

  const changeCustomization = (name, value) => {
    let newValues = { ...customization, [name]: value }
    localStorage["pos-saved-customization"] = JSON.stringify(newValues)
    setCustomization(newValues)
  }

  return (
    <PersonalSettingsContext.Provider
      value={{ localAppParams, changeLocalAppParam, changeCustomization, customization }}
    >
      {props.children}
    </PersonalSettingsContext.Provider>
  )
}

export { PersonalSettingsContext, PersonalSettingsProvider }