import axios from 'axios'

var axiosReq = axios.create({
    timeout: 15000,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
})

function RegStep5(props) {

    const { activeScreen, setActiveScreen, userData, setInitUUID } = props

    const submit = () => {
        let body = userData
        axiosReq.post(`https://ipanel.okeoke.io/api/v1/brand/init/submit`, body).then(res => {
            if(res.data.success) {
                setInitUUID(res.data.data.initUUID)
                setActiveScreen("reg-step6")
            } else {
                console.log(res.data)
            }
        })
    }

    if(activeScreen !== "reg-step5") return null

    return(
        <div className="device-login-reg-step step5">
            <div className="welcome-message">Regisztráció</div>
            <div className="hidden appear welcome-message2">Kérjük ellenőrizd le, hogy az általad megadott adatok helyesek-e.</div>
            <div className="hidden appear company-data-row">
                <div className="label">
                    E-mail cím:
                </div>
                <div className="value">
                    {userData.accountOwner.email}
                </div>
            </div>
            <div className="hidden appear company-data-row">
                <div className="label">
                    Teljes név:
                </div>
                <div className="value">
                    {`${userData.accountOwner.lastName} ${userData.accountOwner.firstName}`}
                </div>
            </div>
            <div className="hidden appear company-data-row">
                <div className="label">
                    Üzlet cím:
                </div>
                <div className="value">
                    {`${userData.location.address.zip}, ${userData.location.address.city}, ${userData.location.address.street} ${userData.location.address.streetNumber}.`}
                </div>
            </div>
            <div className="hidden appear  btn-container">
                <div className="btn highlighted" onClick={submit}>MEGERŐSÍTEM</div>
                <div className="btn" onClick={() => setActiveScreen("reg-step4")}>vissza</div>
            </div>
        </div>
    )
}
export default RegStep5