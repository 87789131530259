import { useState, useContext } from "react"
import useMultilang from "intl/useMultilang"
import TextField from "components/TextField"
import { toast } from 'react-toastify'
import Actions from "./Actions"
import { WizardContext } from "./WizardContext"
import useLocationsConfigApi from "apis/locations/useLocationsConfigApi"

const types = {
    "noServiceFee": 0,
    "normal": 1,
    "reversed": 2
}

function ServiceFee() {

    const { activeStep, goToPrev, goToNext } = useContext(WizardContext)

    const { setLocationConfig } = useLocationsConfigApi()
    const { getT } = useMultilang()
    
    const [selectedOption, setSelectedOption] = useState("noServiceFee")
    const [serviceFeeValue, setServiceFeeValue] = useState("")


    const handleClickPrev = () => {
        goToPrev()
    }

    const handleClickNext = () => {
        if(selectedOption !== "noServiceFee") {
            if(serviceFeeValue !== "" && serviceFeeValue >= 0) {
                saveLocalConfig({localConfig: { serviceFee: { type: types[selectedOption], percent: {pos: serviceFeeValue} } }})
            }
        }
    }

    const saveLocalConfig = (params) => {
        setLocationConfig(params).then(res => {
            if(res.success) {
                goToNext()
            } else {
                toast.error(getT("error"))
            }
        })
    }

    if(activeStep !== "serviceFee") return null

    return (
        <>
        <div className="wizard-step">
            <div className="service-fee-settings">
                <div className="title">{getT("settingWizard.serviceFee.title")}</div>
                <div className="outer-container">
                    <div className="settings-container">
                        <div className="choose">
                            <div className={`btn ${selectedOption === "noServiceFee" ? "btn-highlighted" : ""}`} onClick={() => setSelectedOption("noServiceFee")}>
                                {getT("settingWizard.serviceFee.noServiceFee")}
                            </div>
                            <div className={`btn ${selectedOption === "normal" ? "btn-highlighted" : ""}`} onClick={() => setSelectedOption("normal")}>
                                {getT("settingWizard.serviceFee.normal")}
                            </div>
                            <div className={`btn ${selectedOption === "reversed" ? "btn-highlighted" : ""}`} onClick={() => setSelectedOption("reversed")}>
                                {getT("settingWizard.serviceFee.reversed")}
                            </div>
                        </div>
                        {(selectedOption === "normal" || selectedOption === "reversed") &&
                            <TextField 
                                label={getT("settingWizard.serviceFee.value")}
                                name="serviceFee"
                                onChange={e => setServiceFeeValue(e.target.value)}
                                value={serviceFeeValue}
                                type="number"
                            />}
                    </div>
                    <div className="help-text-container">{getT("settingWizard.serviceFee.helpText")}</div>
                </div>
            </div>
        </div>
        <Actions onClickPrev={handleClickPrev} onClickNext={handleClickNext} />
        </>
    )
}
export default ServiceFee