import useMultilang from "intl/useMultilang"
import { useContext } from "react"
import Actions from "./Actions"
import { WizardContext } from "./WizardContext"

function Welcome() {

    const { getT } = useMultilang()

    const { activeStep, goToNext, goToPrev } = useContext(WizardContext)

    const handleClickPrev = () => {
        goToPrev()
    }

    const handleClickNext = () => {
        goToNext()
    }

    if(activeStep !== "welcome") return null

    return(
        <>
            <div className="wizard-step">
                <div className="title">{getT("settingWizard.welcomeTitle")}</div>
                <div className="text">{getT("settingWizard.welcomeText")}</div>
            </div>
            <Actions onClickPrev={handleClickPrev} onClickNext={handleClickNext} />
        </>
    )
}
export default Welcome