import { useState } from "react"

function RegStep1(props) {

    const { activeScreen, setActiveScreen, handleChangeUserData, userData } = props

    const [error, setError] = useState(false)

    const handleBlur = (e) => {
        let emailValid = validateEmail(e.target.value)
        if(emailValid) {
            setError(false)
            localStorage['reguserEmail'] = e.target.value
            handleChangeUserData("accountOwner", {...userData, email: e.target.value})
        } else {
            setError(true)
        }
    }

    const handleChange = (e) => {
        let emailValid = validateEmail(e.target.value)
        if(emailValid) {
            setError(false)
        } else {
            setError(true)
        }
    }

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    }

    const handleNext = () => {
        if(!error && userData.email !== "") setActiveScreen("reg-step2")
    }

    if(activeScreen !== "reg-step1") return null

    return(
        <div className="hidden appear device-login-reg-step step2">
            <div className="welcome-message">Regisztráció</div>
            <div className="welcome-message2">Elsőnek kérlek add meg az e-mail címedet</div>
            <input className={`${error ? "error" : ""}`} placeholder="valaki@gmail.com" onBlur={handleBlur} onChange={handleChange} defaultValue={userData.email}/>
            <div className="welcome-message3">Az itt megadott e-mail-címre fogjuk küldeni az aktiváló e-mail-t, frissítéseket és egyéb fontos információkat.</div>
            <div className="btn-container">
                <div className={`btn highlighted ${error ? "disabled" : ""}`} onClick={handleNext}>TOVÁBB</div>
                <div className="btn" onClick={() => setActiveScreen("welcome")}>vissza</div>
            </div>
        </div>
    )
}
export default RegStep1