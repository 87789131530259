import { useState, useEffect } from "react"
import axios from 'axios'
import InputMask from "react-input-mask"

var axiosReq = axios.create({
    timeout: 15000,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
})

function RegStep3(props) {

    const { activeScreen, setActiveScreen, handleChangeUserData, userData } = props

    const [companyData, setCompanyData] = useState(userData)
    //const [cid, setCid] = useState(userData.companyID)
    const [taxNumber, setTaxNumber] = useState(userData.taxNumber)
    //const [cidError, setCidError] = useState(false)
    const [taxError, setTaxError] = useState(false)

    useEffect(() => {
        handleChangeUserData("company", companyData)
        // eslint-disable-next-line
    }, [companyData])

    const getTaxData = (taxNumber) => {

        if(taxNumber == null || taxNumber === "") return null

        let data = { 
            taxNumber: "",
            name: "",
            shortName: "",
            address: {
                city: "",
                countryCode: "HU",
                floor: "",
                state: "",
                street: "",
                streetNumber: "",
                telNumber: "",
                zip: ""
            }
        }

        try {
            axiosReq.get(`https://ipanel.okeoke.io/api/v1/common/tax/${taxNumber}`).then(res => {
                if(res.data.success) {
                    data = { 
                        taxNumber,
                        name: res.data.data.name,
                        shortName: res.data.data.shortName,
                        address: {
                            city: res.data.data.city,
                            countryCode: "HU",
                            floor: res.data.data.floor,
                            state: "",
                            street: res.data.data.street,
                            streetNumber: res.data.data.streetNumber,
                            telNumber: "",
                            zip: res.data.data.zip
                        }
                    }
                    setCompanyData({...companyData, ...data})
                    setTaxError(false)
                } else {
                    setCompanyData({...companyData, ...data})
                    setTaxError(true)
                }
            })
            
        } catch(e) {
            console.log(e)
        }
    }

    const handleNext = () => {
        if((userData.name !== "" && userData.taxNumber !== "")) setActiveScreen("reg-step4")
    }

    const handleChange = (e) => {
        let value = formatNumber(e.target.value)
        setTaxError(value.length !== 8)
        if(value.length === 8) getTaxData(value)
        setTaxNumber(value)
    }

    // const handleBlurCID = (e) => setCompanyData({...companyData, companyID: e.target.value})

    // const handleChangeCID = (e) => {
    //     let value = formatNumber(e.target.value)
    //     setCidError(value.length !== 12)
    //     setCid(value)
    // }

    const formatNumber = input => replaceAll(input, {'_': '', ' ': ''})

    const replaceAll = (str, mapObj) => {
        if(str == null) return null
        let re = new RegExp(Object.keys(mapObj).join("|"),"gi")
    
        return str.replace(re, function(matched){
            return mapObj[matched.toLowerCase()]
        })
    }

    if(activeScreen !== "reg-step3") return null

    return(
        <div className="device-login-reg-step step3">
            <div className="welcome-message">Regisztráció</div>
            <div className="hidden appear welcome-message2">A továbbiakban szükségünk lesz a céged adataira.</div>
            {/* <InputMask
                mask="99-99-999999"
                name="companyID"
                type="text"
                className={`hidden appear textField-input ${cidError ? "error" : ""}`}
                value={cid}
                onChange={handleChangeCID}
                onBlur={handleBlurCID}
                placeholder="Cégjegyzékszám"
            /> */}
            <InputMask
                mask="99999999"
                name="taxNumber"
                type="text"
                className={`hidden appear textField-input ${taxError ? "error" : ""}`}
                value={taxNumber}
                onChange={handleChange}
                placeholder="Adószám"
            />
            <div className="hidden appear  welcome-message3">Az adószám megadását követően automatikusan kitöltjük a többi adatot.</div>
            <div className="data-fields">
                {userData.name !== "" && <>
                <div className="hidden appear company-data-row">
                    <div className="label">
                        Név:
                    </div>
                    <div className="value">
                        {userData.name}
                    </div>
                </div>
                <div className="hidden appear company-data-row">
                    <div className="label">
                        Rövid név:
                    </div>
                    <div className="value">
                        {userData.shortName}
                    </div>
                </div>
                <div className="hidden appear company-data-row">
                    <div className="label">
                        Cím:
                    </div>
                    <div className="value">
                        {`${userData?.address?.zip || ""} ${userData?.address?.city || ""} ${userData?.address?.street || ""} ${userData?.address?.streetNumber || ""}`}
                    </div>
                </div>
                </>}
            </div>
            <div className="hidden appear  btn-container">
                <div className={`btn highlighted ${(!taxError && userData.name !== "") ? "" : "disabled"}`} onClick={handleNext}>TOVÁBB</div>
                <div className="btn" onClick={() => setActiveScreen("reg-step2")}>vissza</div>
            </div>
        </div>
    )
}
export default RegStep3