import React, { useState, useEffect, useRef } from "react";
import TextField from ".";

const languages = ["en", "hu"]

function MultiLang(props) {
  const { name, onChange, value, handleBlur, type } =
    props;
    
  const localValueRef = useRef(null)
  const [localValue, setLocalValue] = useState(null);
  const handleChange = (e) => {
    let data = e;
    data = { ...localValue, [e.target.name]: e.target.value }
    setLocalValue({...data})
  };

  const onBlur = (e) => {
    let data = {
      target: {
        name,
        value: localValueRef.current,
      },
    };
    if(typeof handleBlur === "function") handleBlur(data)
  }

  useEffect(() => {
    localValueRef.current = localValue
  },[localValue])

  useEffect(() => {
    return () => {
      let data = {
        target: {
          name,
          value: localValueRef.current,
        },
      };
      if (typeof onChange === "function") onChange(data);
    };
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setLocalValue(value)
  }, [value])



  if(localValue == null) return null

  return (
      <div className="multilang-modal">
        {languages.map(lang =>
          <TextField
            key={lang}
            label={lang}
            name={lang}
            onChange={handleChange}
            onBlur={onBlur}
            value={localValue[lang]}
            type={type != null ? type : "text"}
            fullWidth
          />
        )}
      </div>
  );
}

export default MultiLang;
