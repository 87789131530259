import { useState, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import LoadSvg from "components/LoadSvg-v2"

function Numpad(props) {

  const { onChange, type, limit, defaultValue } = props
  
  const [currentAmount, setCurrentAmount] = useState(""+(defaultValue || 0))

  useEffect(() => {
    if(typeof onChange === "function") onChange(currentAmount)
    // eslint-disable-next-line
  }, [currentAmount])

  const changeAmount = (e) => {
    if (e === "." && (""+currentAmount).includes(".")) return null
    if (""+currentAmount === "0" && e === "0") return null
    if (""+currentAmount === "0" && e === "00") return null
    if (""+currentAmount === "0" && e === ".") {
      setCurrentAmount("0.")
    } else {
      if (""+currentAmount === "0") {
        setCurrentAmount("" + e);
      } else {
        let maxLength = limit || 10
        if(currentAmount.length < maxLength) setCurrentAmount(currentAmount + "" + e)
      }
    }
  }

  return (
    <>
    <div className="numpad-root">
      <div className="current-number">
        {type == null && <NumberFormat value={parseFloat(currentAmount)} decimalSeparator="," suffix=" Ft" thousandSeparator="." displayType="text"/>}
        {type === "basic" && <span>{currentAmount}</span>}
        <div className="clear" onClick={() => setCurrentAmount("0")}><LoadSvg name="BinIcon" /></div>
      </div>
      <div className="numpad-body">
        <div className="numpad-btn" onClick={() => changeAmount("7")}>
          <div className="number">7</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("8")}>
          <div className="number">8</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("9")}>
          <div className="number">9</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount(".")}>
          <div className="number">,</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("4")}>
          <div className="number">4</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("5")}>
          <div className="number">5</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("6")}>
          <div className="number">6</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("0")}>
          <div className="number">0</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("1")}>
          <div className="number">1</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("2")}>
          <div className="number">2</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("3")}>
          <div className="number">3</div>
        </div>
        <div className="numpad-btn" onClick={() => changeAmount("00")}>
          <div className="number">00</div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Numpad
