import { useState, useEffect } from "react"
import { useClient } from "okeoke.client"
//import useMultilang from "intl/useMultilang"
import NumberFormat from "react-number-format"

function TableList(props) {

    const { selectedTableUUID, handleSelectTable, orders } = props

    const { client, selectedLocationUUID } = useClient()

    //const { getT } = useMultilang()
    const [tableList, setTableList] = useState(null)
    const [orderListByTable, setOrderListByTable] = useState(null)

    useEffect(() => {
        if(orders != null) {
            let obj = {}
            for(let order of Object.values(orders)) {
                if(order.tableUUID != null) {
                    if(obj[order.tableUUID] == null) {
                        obj[order.tableUUID] = [order]
                    } else {
                        obj[order.tableUUID] = [...obj[order.tableUUID], order]
                    }
                } 
            }
            setOrderListByTable(obj)
        }
    }, [orders])

    useEffect(() => {
        if(orderListByTable != null && client?.catalog?.tables != null) {
            let obj = {}
            for(let table of Object.values(client?.catalog?.tables || {}).sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1)) {
                let tableOrders = orderListByTable?.[table.uuid]
                if(table.locationUUID === selectedLocationUUID) obj[table.uuid] = {...table, tableOrders}
            }
            setTableList(obj)
        }
        // eslint-disable-next-line
    }, [client, orderListByTable])

    return(
        <div className="table-list-container">
            {Object.values(tableList ||{})
            .sort((a,b) => (a?.tableOrders || []).length > (b?.tableOrders || []).length ? -1 : 1)
            .map(table => 
                <div key={table.uuid} className={`table-card ${selectedTableUUID === table.uuid ? "selected" : ""}`} onClick={() => handleSelectTable(table.uuid)}>
                    {(table?.tableOrders || []).length > 0 && <div className="table-badge">{(table?.tableOrders || []).length}</div>}
                    <div className="table-name">{table.name}</div>
                    <div className="table-payments-needed">
                        <NumberFormat
                            value={+table?.tableOrders?.reduce((sum, a) => sum + +(a?.paymentsNeeded || 0), 0)}
                            decimalSeparator=","
                            suffix=" Ft"
                            thousandSeparator="."
                            displayType="text"
                        />
                    </div>
                </div>
            )}
        </div>
    )
}
export default TableList