import { useContext } from "react"
import { MainContainerContext } from "contexts/MainContainerProvider"
import InfoBox from "./InfoBox"

function Header() {

  const { header } = useContext(MainContainerContext)

  return (
    <div className="header-root">
      <InfoBox />
      <div className="header-main-container">{header}</div>
    </div>
  )
}

export default Header
