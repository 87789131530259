import { useIPanelApi, useClient } from "okeoke.client"


export default function useNtakApi() {
  
  const { get, post } = useIPanelApi()
  const { brandID } = useClient()
    
    const getNtakCategories = async () => {
      try {
        const response = await get(`ntak/basic/categories`)
        return response
      } catch (error) {
        console.log(error)
        return { success: false }
      }
    }

    const getProviders = async () => {
      try {
        const response = await get(`ntak/providers/brands/${brandID}`)
        return response
      } catch (error) {
        console.log(error)
        return { success: false }
      }
    }

    const getProvider = async (providerID) => {
      try {
        const response = await get(`ntak/providers/brands/${brandID}/${providerID}`)
        return response
      } catch (error) {
        console.log(error)
        return { success: false }
      }
    }

    const setProviders = async (provider) => {
      let body = {
          appName: "brands",
          appID: brandID,
          provider
      }
      try {
        const response = post(`ntak/providers/`, body)
        return response
      } catch (error) {
        console.log(error)
        return { success: false }
      }
    }

    return {
      getNtakCategories,
      getProviders,
      getProvider,
      setProviders
    }
} 