import { useContext } from "react"
import LanguageSelector from "components/LanguageSelector"
import { useClient } from "okeoke.client"
import { ModalContext } from "contexts/ModalContext"
import useMultilang from "intl/useMultilang"

function LanguageDialog(props) {
  const { popModal } = useContext(ModalContext);
  const { languages } = useClient();
  const {getT,  language,setSelectedLanguage} = useMultilang()

  const handleListItemClick = (code) => {
    setSelectedLanguage(code);
  };

  return (
    <div className="language-selector-modal">
      <div className="language-selector-modal-title">{getT("languageSelector.modal.title")}</div>
      <LanguageSelector
        languages={languages}
        selectedLanguage={language}
        onLanguageSelect={handleListItemClick}
      />
      <div className="language-selector-modal-button-container">
        <div className="btn btn-highlighted" onClick={popModal}>
        {getT("languageSelector.modal.close")}
        </div>
      </div>
    </div>
  );
}

export default LanguageDialog;
