import { useState, createContext, useContext, useEffect } from "react"
import { MainContext } from "contexts/MainContext"
import { ModalContext } from "contexts/ModalContext" 
import Header from "./Header"

const WizardContext = createContext()

const WizardContextProvider = (props) => {

  const { location } = props

  const { setSettingsWizardDone } = useContext(MainContext)
  const { popModal } = useContext(ModalContext)

  const [steps, setSteps] = useState(null)
  const [activeStep, setActiveStep] = useState(null)
  const [finished, setFinished] = useState(false)
  const [stepsDone, setStepsDone] = useState([])

  useState(() => {
    if(location != null) {

      let stepsArray = ["welcome"]

      if(location.ntakProviderID == null || location.ntakProviderID === "" ||  location.ntakProviderID === 0) stepsArray.push("ntak")
      stepsArray.push("fiscalPrinter")
      stepsArray.push("posPrinter")
      if(location.localConfig?.tippType == null || location.localConfig?.tippType === "") stepsArray.push("tippType")
      if(location.localConfig?.serviceFee?.type == null) stepsArray.push("serviceFee")
      stepsArray.push("done")

      setSteps(stepsArray)
      setActiveStep(stepsArray[0])
    }
    
  }, [location])

  useEffect(() => {
    if(steps != null && (steps || []).length-1 === stepsDone.length) setFinished(true)
  }, [stepsDone, steps])

  const goToNext = () => {
    let nextStepId = steps.indexOf(activeStep)+1
    if(nextStepId <= (steps || []).length-1) {
        setActiveStep(steps[nextStepId])
        if(!stepsDone.includes(activeStep)) {
            let newValues = [...stepsDone]
            newValues.push(activeStep)
            setStepsDone(newValues)
        }
    }
}

  const goToPrev = () => {
    let nextStepId = steps.indexOf(activeStep)-1
    if(nextStepId >= 0) setActiveStep(steps[nextStepId])
  }

  const handleClickFinish = () => {
    if(finished) {
        localStorage['settingsWizardDone'] = 1
        setSettingsWizardDone(true)
        popModal()            
    }
  }

  const handleSetActiveStep = (step) => {
    if((steps.indexOf(step) <= steps.indexOf(activeStep)) || stepsDone.includes(step)) setActiveStep(step)
  }

  return (
    <WizardContext.Provider value={{ goToNext, goToPrev, handleClickFinish, handleSetActiveStep, steps, activeStep, finished, stepsDone }}>
      <div className="settings-wizard">
        {(steps == null || activeStep == null) && <div className="loading-container">Loading...</div>}
        {steps != null && activeStep != null && <Header />}
        {steps != null && activeStep != null && props.children}
      </div>
    </WizardContext.Provider>
  )
}

export { WizardContext, WizardContextProvider }