import { useState, createContext, useRef, useEffect } from "react"
import { v4 as uuidv4 } from "uuid"
import Modal from "components/Modal/Modal"

const ModalContext = createContext()

const ModalProvider = (props) => {

  const openModalsRef = useRef([])
  const [openModals, setOpenModals] = useState([])

  useEffect(() => {
    openModalsRef.current = openModals
  }, [openModals])

  const popModal = () => {
    openModalsRef.current.pop()
    setOpenModals([...openModalsRef.current])
  }

  const addModal = (modal) => {
    openModalsRef.current.push(modal)
    setOpenModals([...openModalsRef.current])
  }

  const loadModal = (modal, onClickLayout) => {
    addModal(
      <Modal key={uuidv4()} className="pos-modal-base-skin" onClickLayout={onClickLayout}>
        {modal}
      </Modal>
    )
  }

  const closeAllModal = () => {
    openModalsRef.current = []
    setOpenModals(openModalsRef.current)
  }

  return (
    <ModalContext.Provider value={{ addModal, loadModal, popModal, closeAllModal }}>
      {openModals}
      {props.children}
    </ModalContext.Provider>
  )
}

export { ModalContext, ModalProvider }
