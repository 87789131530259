import { useContext } from "react"
import StepCircle from "./StepCircle"
import { WizardContext } from "./WizardContext"

function Header() {

    const { steps, handleSetActiveStep } = useContext(WizardContext)

    return(
        <div className="header">
            <div className="line"></div>
            {(steps || []).map((step, index) => 
                <StepCircle 
                    key={index}
                    stepName={step}
                    number={index+1}
                    onClick={() => handleSetActiveStep(step)}
                />
            )}
        </div>
    )
}
export default Header