import { useIPanelApi, useClient } from "okeoke.client"

export default function useOrdersApi() {

    const { get } = useIPanelApi()
    const { brandID } = useClient()
    
    const getOrders = async (filter) => {
      try {
        const response = await get(`brand/orders?brandID=${brandID}&filter=${filter}`)
        return response
      } catch (error) {
        console.log(error)
        return { success: false }
      }
    }

    const getSelectedOrder = async (selectedOrderUUID) => {
      try {
        const response = await get(`brand/orders/${selectedOrderUUID}?brandID=${brandID}`)
        return response
      } catch (error) {
        console.log(error)
        return { success: false }
      }
    }

    return {
      getOrders,
      getSelectedOrder
    }
} 