import { useState } from "react"
import InputMask from "react-input-mask"

function RegStep2(props) {

    const { activeScreen, setActiveScreen, handleChangeUserData, userData } = props

    const [error, setError] = useState(false)
    const [localTelNumber, setLocalTelNumber] = useState(userData.telNumber)

    const handleBlur = (e) => {
        let value = e.target.value

        if(e.target.name === "telNumber") {
            value = formatPhoneNumber(e.target.value)
            setError(value.length !== 12)
        }
        handleChangeUserData("accountOwner", {...userData, [e.target.name]: value})
    }

    const handleNext = () => {
        if(!error && userData.firstName !== "" && userData.lastName !== "" && userData.telNumber !== "") setActiveScreen("reg-step3")
    }

    const replaceAll = (str, mapObj) => {
        if(str == null) return null
        let re = new RegExp(Object.keys(mapObj).join("|"),"gi")
    
        return str.replace(re, function(matched){
            return mapObj[matched.toLowerCase()]
        })
    }

    const formatPhoneNumber = input => replaceAll(input, {'_': '', ' ': ''})

    const handleChange = (e) => {
        let value = e.target.value
        if(e.target.name === "telNumber") {
            value = formatPhoneNumber(e.target.value)
            setError(value.length !== 12)
        }
        setLocalTelNumber(value)
        if(value.length === 12) handleChangeUserData("accountOwner", {...userData, telNumber: value})
    }

    if(activeScreen !== "reg-step2") return null

    return(
        <div className="device-login-reg-step step2">
            <div className="welcome-message">Regisztráció</div>
            <div className="hidden appear welcome-message2">Hogyan szólíthatunk téged és milyen számon tudunk felhívni?</div>
            <input className="hidden appear" name="firstName" defaultValue={userData.firstName} placeholder="Keresztnév" onBlur={handleBlur} />
            <input className="hidden appear" name="lastName" defaultValue={userData.lastName} placeholder="Vezetéknév" onBlur={handleBlur} />
            <InputMask
                mask="+36 99 999 9999"
                name="telNumber"
                type="text"
                className={`textField-input ${(localTelNumber !== "" && error) ? "error" : ""}`}
                value={localTelNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Telefonszám"
            />
            <div className="hidden appear  welcome-message3">Az itt megadott adatok mind fontosak lesznek a fiók létrehozásában.</div>
            <div className="hidden appear  btn-container">
                <div className={`btn highlighted ${(!error && userData.firstName !== "" && userData.lastName !== "" && userData.telNumber !== "") ? "" : "disabled"}`} onClick={handleNext} >TOVÁBB</div>
                <div className="btn" onClick={() => setActiveScreen("reg-step1")}>vissza</div>
            </div>
        </div>
    )
}
export default RegStep2