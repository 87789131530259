import useMultilang from "intl/useMultilang"

function NotAvailable() {

    const { getT } = useMultilang()

    return(
        <div className="not-available-root">{getT("notAvailable")}</div>
    )
}
export default NotAvailable