import { createContext, useEffect, useState, useRef } from "react"

const InputHandlerContext = createContext()

function InputHandlerProvider(props) {

  const previousKeyDown = useRef({})
  const machineText = useRef("")
  const timerPiece = useRef(0)
  const counterRef = useRef(null)
  const isMachine = useRef(false)
  const machineProvidedFunction = useRef("")
  const notMachineProvededFunction = useRef("")
  const providedFunction = useRef("")

  const [machineValue, setMachineValue] = useState(null)

  const setMachineProvidedFunction = (value) => {
    machineProvidedFunction.current = value
  }

  const setNotMachineProvidedFunction = (value) =>{
    machineProvidedFunction.current = value
  }

  const setProvidedFunction = (value) => {
    providedFunction.current = value
  }


  const keydown = (e) => {
    if(e.key === "Enter") return null
    timerPiece.current = 1
    if (e.timeStamp - previousKeyDown.current.timeStamp < 40) {
      e.preventDefault()
      machineText.current = machineText.current + e.key;
      isMachine.current = true
    } else {
      machineText.current = e.key
    }
    if (typeof providedFunction.current === "function") {
      providedFunction.current(e.key)
    }
    previousKeyDown.current = e
    if (counterRef.current == null) {
      countDown()
    }
  };

  const countDown = () => {
    if (timerPiece.current === 1) {
      timerPiece.current = 0
      counterRef.current = setTimeout(() => {
        countDown()
      }, 40)
    } else {
      if(isMachine.current){
        timeOverMachine()
      }else{
        timeOver()
      }
      counterRef.current = null
    }
  };

  const timeOverMachine = () => {
    setMachineValue(machineText.current);
    if (typeof machineProvidedFunction.current === "function") {
      machineProvidedFunction.current(machineText.current);
    }
  };

  const timeOver = () => {
    if(typeof notMachineProvededFunction.current === "function"){
      notMachineProvededFunction.current(machineText)
    }
  }

  useEffect(() => {
    if (machineValue != null) {
      setMachineValue(null)
      isMachine.current= false
    }
  }, [machineValue])

  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      keydown(e)
    })
    // eslint-disable-next-line
  }, [])

  return (
    <InputHandlerContext.Provider
      value={{
        providedFunction: providedFunction.current,
        setProvidedFunction,
        machineValue,
        setMachineValue,
        machineProvidedFunction: machineProvidedFunction.current,
        setMachineProvidedFunction,
        notMachineProvededFunction :notMachineProvededFunction.current,
        setNotMachineProvidedFunction
      }}
    >
      {props.children}
    </InputHandlerContext.Provider>
  )
}

export { InputHandlerProvider, InputHandlerContext }
