import useMultilang from "intl/useMultilang"

function NtakModal(props) {

    const { connected, popModal } = props
    
    const { getT } = useMultilang()
    
    return(
        <div className="plugged-modal">
            <div className="text">{getT(`ntak.${connected ? "connected" : "offline"}`)}</div>
            <div className="btn btn-highlighted" onClick={popModal}>{getT(`ntak.done`)}</div>
        </div>
    )
}
export default NtakModal