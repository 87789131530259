import { useState } from 'react'
import Numpad from './TipNumpad'
import NumberFormat from 'react-number-format'
import LoadSvg from "components/LoadSvg-v2"
import useMultilang from "intl/useMultilang"

function TipModal(props) {

    const { paymentsNeeded, tipAmount, popModal, onSubmit } = props
    
    const { getT } = useMultilang()

    const [localTip, setLocalTip] = useState(tipAmount)

    const handleChangeTip = (value) => {
        setLocalTip(Math.round(value))
    }

    const handleSubmit = () => {
        onSubmit(localTip)
    }

    return(
        <>
        <div className="tip-modal-content">
            <div className="header">
                <div className="back-icon" onClick={popModal}>
                    <LoadSvg name={"ArrowLeftIcon"} />
                </div>
                <div className="text">
                    {getT("tipModal.back")}
                </div>
            </div>
            <div className="body">
                <div className="total-amount">{getT("tipModal.percent")}</div>
                <div className="percents-container">
                    <div className="btn" onClick={() => handleChangeTip((paymentsNeeded-tipAmount)*0.1)}>10%</div>
                    <div className="btn" onClick={() => handleChangeTip((paymentsNeeded-tipAmount)*0.12)}>12%</div>
                    <div className="btn" onClick={() => handleChangeTip((paymentsNeeded-tipAmount)*0.15)}>15%</div>
                    <div className="btn" onClick={() => handleChangeTip((paymentsNeeded-tipAmount)*0.2)}>20%</div>
                </div>
                <div className="total-amount">{getT("tipModal.totalAmount")}</div>
                <div className="amounts-container">
                    <div className="btn" onClick={() => handleChangeTip(Math.ceil((paymentsNeeded-tipAmount)/500)*500 - (paymentsNeeded-tipAmount))}>{`${Math.ceil((paymentsNeeded-tipAmount)/500)*500} Ft`}</div>
                    <div className="btn" onClick={() => handleChangeTip(Math.ceil((paymentsNeeded-tipAmount)/1000)*1000 - (paymentsNeeded-tipAmount))}>{`${Math.ceil((paymentsNeeded-tipAmount)/1000)*1000} Ft`}</div>
                </div>
                <div className="numpad-container">
                    <Numpad value={localTip} paymentsWithoutTip={(paymentsNeeded-tipAmount)} onChange={handleChangeTip} />
                    <div className="btn btn-highlighted" onClick={handleSubmit}>
                        <NumberFormat value={+paymentsNeeded-tipAmount+localTip} decimalSeparator="," prefix={`${getT("tipModal.sum")}: `} suffix=" Ft" thousandSeparator="." displayType="text"/>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default TipModal