import { useClient } from "okeoke.client"
import { useState, useEffect } from "react"
import useMultilang from "intl/useMultilang"

function TableModal(props) {

    const { setTable, tableInfo, popModal } = props

    const { client, selectedLocationUUID } = useClient()

    const { getT } = useMultilang()
    const [tableList, setTableList] = useState(null)

    useEffect(() => {
        if(client?.catalog?.tables != null) {
            let obj = {}
            for(let table of Object.values(client?.catalog?.tables || {}).sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1)) {
                if(table.locationUUID === selectedLocationUUID) obj[table.uuid] = table
            }
            setTableList(obj)
        }
        // eslint-disable-next-line
    }, [client])

    const handleSelectTable = (tableUUID) => {
        setTable(tableUUID)
        popModal()
    }

    return(
        <div className="table-modal-content">
            <div className="title">{getT("tableModal.title")}</div>
            <div className="table-cards-container">
                <div className={`table-card ${tableInfo?.uuid == null ? "selected" : ""}`} onClick={() => handleSelectTable(null)}>
                    <div className="table-name">{getT("tableModal.noTableSelected")}</div>
                    <div className="table-number"></div>
                    <div className="table-shortID"></div>
                </div>
                {Object.values(tableList ||{}).map(table => 
                    <div key={table.uuid} className={`table-card ${tableInfo?.uuid === table.uuid ? "selected" : ""}`} onClick={() => handleSelectTable(table.uuid)}>
                        <div className="table-name">{table.name}</div>
                        <div className="table-number">{table.number}</div>
                        <div className="table-shortID">{table.shortID}</div>
                    </div>
                )}
            </div>
        </div>
    )
}
export default TableModal