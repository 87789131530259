import React from "react";
import LoadSvg from "components/LoadSvg-v2";

export default function LanguageSelector(props) {
  const { onLanguageSelect, languages, selectedLanguage } = props;

  const selectLanguage = (code) => {
    if (typeof onLanguageSelect === "function") onLanguageSelect(code);
  };

  return (
    <div className="language-selector-modal-container">
      {Object.values(languages).map((item) => {
        return (
          <div
            key={item.code}
            className={`language-selector-item ${selectedLanguage === item.code ? "selected" : ""}`}
            onClick={() => {
              selectLanguage(item.code);
            }}
          >
            <div className="item-flag">
              <LoadSvg name={"FlagRoundedIcon" + item.code.toUpperCase()} />
            </div>
            <div className="item-name t20">{item.name}</div>
          </div>
        );
      })}
    </div>
  );
}
