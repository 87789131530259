import { useState, useEffect } from "react"
import useMultilang from 'intl/useMultilang'
import TextField from "components/TextField"
import LoadSvg from "components/LoadSvg-v2"

function EditProvider(props) {

    const { onDataUpdate, data, validated } = props

    const { getT } = useMultilang()

    const [localData, setLocalData] = useState({...data})

    let fileReader

    useEffect(() => {
        onDataUpdate(localData)
        // eslint-disable-next-line
    }, [localData])

    const handleChangeData = (e) => {
        setLocalData({...localData, [e.target.name]: e.target.value})
    }

    const handleFileRead = () => {
        const content = fileReader.result
        if(content.includes("-----BEGIN CERTIFICATE-----")) {
            setLocalData({...localData, certificate: content})
        }
        if(content.includes("-----BEGIN PRIVATE KEY-----")) {
            setLocalData({...localData, key: content})
        }
    }

    const handleFileChosen = (file) => {
        fileReader = new FileReader()
        fileReader.onloadend = handleFileRead
        fileReader.readAsText(file)
    }

    return(
        <div className="ntak-provider">
            <div className="input-fields">
                <TextField 
                    label={getT('ntakEdit.regNumber')}
                    name="regNumber"
                    value={localData.regNumber}
                    type="text"
                    onChange={handleChangeData}
                    helperText={(validated && (localData.regNumber === "" || localData.regNumber.length !== 10)) ? getT('ntakEdit.required') : ""}
                    error={validated && (localData.regNumber === "" || localData.regNumber.length !== 10)}
                    maxLength={10}
                />
                <TextField 
                    label={getT('ntakEdit.taxNumber')}
                    name="taxNumber"
                    value={localData.taxNumber}
                    onChange={handleChangeData}
                    helperText={(validated && (localData.taxNumber === "" || localData.taxNumber.replace(/_/g, "").length !== 11)) ? getT('ntakEdit.required') : ""}
                    error={validated && (localData.taxNumber === "" || localData.taxNumber.replace(/_/g, "").length !== 11)}
                    mask="99999999999"
                />
            </div>
            <div className="uploads-container">
                <div className="files">
                    <div className={`certificate-container ${(localData.certificate === "") ? (validated ? "invalid" : "") : "valid"}`}>
                        <div className="icon">
                            {localData.certificate === "" && <LoadSvg name={"XCircle"} />}
                            {localData.certificate !== "" && <LoadSvg name={"check"} />}
                        </div>
                        <div className="text">
                            {localData.certificate === "" && getT('ntakEdit.certificateMissing')}
                            {localData.certificate !== "" && getT('ntakEdit.certificateOk')}
                        </div>
                    </div>
                    <div className={`certificate-container ${(localData.key === "") ? (validated ? "invalid" : "") : "valid"}`}>
                        <div className="icon">
                            {localData.key === "" && <LoadSvg name={"XCircle"} />}
                            {localData.key !== "" && <LoadSvg name={"check"} />}
                        </div>
                        <div className="text">
                            {localData.key === "" && getT('ntakEdit.keyMissing')}
                            {localData.key !== "" && getT('ntakEdit.keyOk')}
                        </div>
                    </div>
                </div>
                <div className="file-upload-container">
                    <div className="upload upload-key">
                        <label htmlFor="ntak-key">
                            <span><LoadSvg name="upload"/></span><span>{getT('ntakEdit.file1')}</span>                        
                            <input style={{display: "none"}} id="ntak-key" type="file" name="key" onChange={e => handleFileChosen(e.target.files[0])}/>
                        </label>
                    </div>
                    <div className="upload upload-certificate">
                        <label htmlFor="ntak-certificate">
                            <span><LoadSvg name="upload"/></span><span>{getT('ntakEdit.file2')}</span>
                            <input style={{display: "none"}} id="ntak-certificate" type="file" name="certificate" onChange={e => handleFileChosen(e.target.files[0])} />
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EditProvider