import useMultilang from "intl/useMultilang"
import { useState, useContext } from "react"
import InputFieldSelect from "components/InputFieldSelect"
import TextField from 'components/TextField'
import { toast } from 'react-toastify'
//import merge from 'lodash.merge'
import Actions from "./Actions"
import { WizardContext } from "./WizardContext"
import { useDevice } from "okeoke.client"
import useDevicesApi from "apis/useDevicesApi"
import { MainContext } from "contexts/MainContext"

const defaultValues = {
    "port": "15000",
    "type": "",
    "ipAddress": "127.0.0.1",
    "vat": {
        "products": {
          "5.00": "1",
          "18.00": "2",
          "27.00": "3"
        },
        "serviceFee": {
          "5.00": "4",
          "18.00": "5",
          "27.00": "6"
        },
        "tipp": "0"
    }
}

function FiscalPrinter() {
    
    const { activeStep, goToPrev, goToNext } = useContext(WizardContext)
    const { setReloadNeeded } = useContext(MainContext)

    const { deviceUUID, deviceParams } = useDevice()
    
    const { setDevice } = useDevicesApi()
    const { getT } = useMultilang()
    const [localParams, setLocalParams] = useState(deviceParams?.printer || defaultValues)

    const handleClickPrev = () => {
        goToPrev()
    }

    const handleClickNext = () => {
        if(localParams.type !== "") {
            let valid = validate()
            if(valid) onChangePrinterData(localParams)
        } else {
            onChangePrinterData(null)
        }
    }

    const onChangePrinterData = (params) => {
        const device = {
          uuid: deviceUUID,
          deviceParams: {
            printer: params
          }
        }
        setDevice(device).then(res => {
          if(res.success) {
            goToNext()
            setReloadNeeded(true)
          } else {
            console.log("Fiscal printer save error", res)
            toast.error(getT("error"))
          }
        })
    }

    const validate = () => {

        let validation = true

        if(localParams?.type === "Fiscat") {
            const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
            if(!ipRegex.test(localParams?.ipAddress)) {
                toast.error("Invalid ip")
                validation = false
            }
        }

        return validation
    }

    const changeFieldData = (e) => {
        setLocalParams({...localParams, [e.target.name]: e.target.value})
    }

    const changeVatServiceFeeFieldData = (e) => {
        setLocalParams({
            ...localParams, 
            vat: {
                ...localParams.vat, serviceFee: {
                    ...localParams.vat.serviceFee, [e.target.name]: e.target.value
                }
            }
        })
    }

    const changeVatProductsFieldData = (e) => {
        setLocalParams({
            ...localParams, 
            vat: {
                ...localParams.vat, products: {
                    ...localParams.vat.products, [e.target.name]: e.target.value
                }
            }
        })
    }

    const changeVatTippFieldData = (e) => {
        setLocalParams({
            ...localParams, 
            vat: {
                ...localParams.vat, tipp: e.target.value
            }
        })
    }

    if(activeStep !== "fiscalPrinter") return null

    return(
        <>
        <div className="wizard-step">
            <div className="fiscal-printer-settings">
                <div className="title">{getT("devices.fiscalPrinterTitle")}</div>
                <div className="fields-container">
                    <div className="printer-settings-fields">
                        <div className="box">
                            <div className="row">
                                <InputFieldSelect 
                                    label={getT("devices.printerType")}
                                    name="type"
                                    onChange={changeFieldData}
                                    value={localParams?.type}
                                    displayedDefaultName={getT("devices.selectPrinterType")}
                                    options={[{ title: "Nincs adóügyi nyomtatóm", value: ""}, { title: "Fiscat", value: "Fiscat"}, { title: "FiscatIPalm", value: "FiscatIPalm"}]}
                                />
                                {localParams?.type === "Fiscat" && <TextField 
                                    label={getT("devices.ipAddress")}
                                    name="ipAddress"
                                    onChange={changeFieldData}
                                    value={localParams?.ipAddress}
                                    type="text"
                                    disabled={localParams?.type !== "Fiscat"}
                                />}
                                {localParams?.type === "Fiscat" && <TextField 
                                    label={getT("devices.port")}
                                    name="port"
                                    onChange={changeFieldData}
                                    value={localParams?.port}
                                    type="text"
                                    disabled={localParams?.type !== "Fiscat"}
                                />}
                            </div>
                        </div>
                        {localParams?.type !== "" && <div className="box vat-box">
                            <div className="title">{getT("devices.vat")}</div>
                            <div className="row">
                                <div className="col">
                                    <div className="sub-title">{getT("devices.vat.products")}</div>
                                    <div className="mt-1">
                                        <TextField 
                                            label={"5.00%"}
                                            name="5.00"
                                            onChange={changeVatProductsFieldData}
                                            value={localParams?.vat?.products?.['5.00']}
                                            type="text"
                                            disabled={localParams?.type === ""}
                                        />
                                    </div>
                                    <div className="mt-1">
                                        <TextField 
                                            label={"18.00%"}
                                            name="18.00"
                                            onChange={changeVatProductsFieldData}
                                            value={localParams?.vat?.products?.['18.00']}
                                            type="text"
                                            disabled={localParams?.type === ""}
                                        />
                                    </div>
                                    <div className="mt-1">
                                        <TextField 
                                            label={"27.00%"}
                                            name="27.00"
                                            onChange={changeVatProductsFieldData}
                                            value={localParams?.vat?.products?.['27.00']}
                                            type="text"
                                            disabled={localParams?.type === ""}
                                        />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="sub-title">{getT("devices.vat.serviceFee")}</div>
                                    <div className="mt-1">
                                        <TextField 
                                            label={"5.00%"}
                                            name="5.00"
                                            onChange={changeVatServiceFeeFieldData}
                                            value={localParams?.vat?.serviceFee?.['5.00']}
                                            type="text"
                                            disabled={localParams?.type === ""}
                                        />
                                    </div>
                                    <div className="mt-1">
                                        <TextField 
                                            label={"18.00%"}
                                            name="18.00"
                                            onChange={changeVatServiceFeeFieldData}
                                            value={localParams?.vat?.serviceFee?.['18.00']}
                                            type="text"
                                            disabled={localParams?.type === ""}
                                        />
                                    </div>
                                    <div className="mt-1">
                                        <TextField 
                                            label={"27.00%"}
                                            name="27.00"
                                            onChange={changeVatServiceFeeFieldData}
                                            value={localParams?.vat?.serviceFee?.['27.00']}
                                            type="text"
                                            disabled={localParams?.type === ""}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-1">
                                <TextField 
                                    label={getT("devices.vat.tipp")}
                                    name="tipp"
                                    onChange={changeVatTippFieldData}
                                    value={localParams?.vat?.tipp || ""}
                                    type="text"
                                    disabled={localParams?.type === ""}
                                />
                            </div>
                        </div>}
                    </div>
                    <div className="help-text-box">
                        {getT("settingWizard.fiscalPrinter.helpText")}
                    </div>
                </div>
            </div>
        </div>
        <Actions onClickPrev={handleClickPrev} onClickNext={handleClickNext} />
        </>
    )
}
export default FiscalPrinter