import { useContext } from "react"
import { PaymentContext } from "contexts/PaymentContext"
import useMultilang from "intl/useMultilang"
import NumberFormat from "react-number-format"

function Summary() {
    
    const { paymentsNeeded, discountPrice, paymentsTotal } = useContext(PaymentContext)
    const { getT } = useMultilang()

    return(
        <div className="payments-summary">
          <div className="box box-left">
            <div className="label">{getT("payment.view.totalPrice")}</div>
            <NumberFormat
              value={discountPrice}
              decimalSeparator=","
              suffix=" Ft"
              thousandSeparator="."
              displayType="text"
            />
          </div>
          <div className="box box-mid">
            <div className="label">{getT("payment.view.paid")}</div>
            <NumberFormat
              value={paymentsTotal}
              decimalSeparator=","
              suffix=" Ft"
              thousandSeparator="."
              displayType="text"
            />
          </div>
          <div className="box box-right">
            <div className="label">{getT("payment.view.paymentsNeeded")}</div>
            <NumberFormat
              value={paymentsNeeded}
              decimalSeparator=","
              suffix=" Ft"
              thousandSeparator="."
              displayType="text"
            />
          </div>
        </div>
    )
}
export default Summary