import { useIPanelApi, useClient } from "okeoke.client"

export default function useLocationsApi() {

  const { post } = useIPanelApi()
  const { brandID, deviceLocationUUID } = useClient()
    
  const setLocationDayState = async (day) => {
    try {

      const body = {
        brandID,
        day: {
          locationUUID: deviceLocationUUID,
          ...day,
        }
      }

      const response = await post(`brand/locations/state/day`, body)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }

  return {
    setLocationDayState
  }
} 